import { DOCUMENT } from '@angular/common';
import {
  Component,
  inject,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';
import { RESOURCES } from '../../constants/resource-service.constants';
import {
  CONFIGURATION_STEPS,
  EXPLORADOR_DIGITAL_STEPS,
  INNOVADOR_LEGAL_STEPS,
  VISIONARIO_JURIDICO_STEPS,
} from '../../constants/user-journey.constants';
import { UserDataLocalStorage } from '../../reducer/user-data/user-data.actions';
import { UisrAuthService } from '../../services/uisr-auth.service';
import { WebSocketService } from '../../services/v2-socket.io.service';
import { SidebarComponent } from '../sidebar/sidebar.component';

@UntilDestroy()
@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
})
export class MainLayoutComponent implements OnInit, OnDestroy {
  private readonly subscriptionService = inject(SubscriptionService);

  @ViewChild(SidebarComponent, { static: true })
  sidebarComponent!: SidebarComponent;
  lastScrollTop: any = 0;
  levelOneSteps = EXPLORADOR_DIGITAL_STEPS;
  levelTwoSteps = CONFIGURATION_STEPS;
  levelThreeSteps = INNOVADOR_LEGAL_STEPS;
  levelFourSteps = VISIONARIO_JURIDICO_STEPS;
  resources = RESOURCES;
  loadingSubscription = new BehaviorSubject(false);
  journeySteps: any[] = [];
  incompleteSteps: any = [];
  differenceInDays: number = 0;
  showParagraph = true;

  constructor(
    private store: Store,
    private renderer: Renderer2,
    private authService: UisrAuthService,
    public socketService: WebSocketService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    this.store.dispatch(new UserDataLocalStorage());
    this.authService.startValidationLoop();
    this.authService.updatePermissions();
    this.authService.updateAssistantSettings();
    this.renderer.addClass(this.document.body, 'inner-app');

    this.setSubscriptionDates();
  }

  openSidebar() {
    this.sidebarComponent.openOrCloseSidebar();
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(this.document.body, 'inner-app');
  }

  private setSubscriptionDates() {
    const subscription = this.subscriptionService.subscription$.value;

    if (subscription?.subscriptionExpire) {
      const currentDate = new Date(); // Fecha actual
      const expirationDate = new Date(subscription.subscriptionExpire); // Fecha de expiración

      // Calcula la diferencia en milisegundos
      const differenceInMs = expirationDate.getTime() - currentDate.getTime();

      // Convertir la diferencia de milisegundos a días (1 día = 86,400,000 ms)
      this.differenceInDays = Math.floor(
        differenceInMs / (1000 * 60 * 60 * 24)
      );
    }

    if (subscription) {
      this.authService.updateFeatureConfig();
      this.authService.updateFeatureAccess();
    }
  }

  hideParagraph() {
    this.showParagraph = false;
    localStorage.setItem('showParagraph', JSON.stringify(this.showParagraph));
  }
}
