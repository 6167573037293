import { AfterViewInit, Component, HostListener, inject } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DateTime } from 'luxon';
import { ASSISTANT_STEPS } from 'src/app/core/constants/assistant-steps.steps';
import { DialogService } from 'src/app/core/services/dialog.service';
import { AppTutorialService } from 'src/app/core/services/tutorial.service';
import { UisrAuthService } from 'src/app/core/services/uisr-auth.service';
import { environment } from 'src/environments/environment';
import { ViewDocComponent } from './components/view-doc/view-doc.component';
import { AssistantChatService } from './services/assistant-chat.service';
import { SimpleDocPickerComponent } from '../simple-doc-picker/simple-doc-picker.component';
import { ALLOWED_AMPARO_EXTS } from './amparo-ext.constants';

@UntilDestroy()
@Component({
  selector: 'app-assistant-chat',
  templateUrl: './assistant-chat.component.html',
  host: {
    class: 'contents',
  },
})
export class AssistantChatComponent implements AfterViewInit {
  steps = ASSISTANT_STEPS;
  assistantTutorialFinalDate = environment.assistantTutorialFinalDate;

  readonly tutorialService = inject(AppTutorialService);
  readonly assistantChatService = inject(AssistantChatService);

  private readonly _dialogService = inject(DialogService);
  private readonly _authService = inject(UisrAuthService);

  @HostListener('window:resize', []) updateMenuStatus() {
    if (window.innerWidth >= 560) {
      this.assistantChatService.componentStates.threadsMenu = true;
    }
  }

  //////// AUN FALTA POR CULMINAR:
  //////// . https://www.notion.so/uisr/c75facf6cedc40c899892fc58e0a0b41?v=8d1ba0543eac4348abc96674cf57e23d&p=1ca8878c655842118bbd0201e75f38ab&pm=s

  ngAfterViewInit(): void {
    if (this.assistantChatService.assistantAvailable()) {
      this.assistantChatService.fetchModels();

      setTimeout(() => {
        if (
          this.tutorialService.shouldStartTourFromDate(
            'keepFirstTimeAssistant',
            DateTime.fromJSDate(this.assistantTutorialFinalDate)
          )
        ) {
          this.tutorialService.startTour(
            this.steps,
            undefined,
            'assistant-tour'
          );
        }
      }, 0);
    }
  }

  /** Obtener el nombre de la conversación */
  public get threadName() {
    return this.assistantChatService.thread?.name || '';
  }

  /** Realiza el llamado al servicio del tutorial para empezar el tour del asistente */
  startTour() {
    this.tutorialService.startTour(this.steps, undefined, 'assistant-tour');
  }

  /** Visualizar un modal con la información de un documento procesado (resumen, keywords...) */
  viewDoc(doc: any) {
    this._dialogService.openDialog(ViewDocComponent, { data: { doc } });
  }

  /** Determina si la opción de agregar documentos está disponible */
  canAddFiles() {
    const settings = this._authService.assistantSettings.getValue();
    return !settings || !settings.idAssistantSettings || settings.allowAccess;
  }

  /** Abre el modal del selector de documentos simple y obtiene los ids de los archivos seleccionados*/
  viewDocs() {
    const docIds = Array.from(
      new Set(
        this.assistantChatService.documents?.map(
          (doc: any) => doc.idActivityFile
        )
      )
    );

    this._dialogService
      .openDialog(SimpleDocPickerComponent, {
        data: {
          prompt:
            'Selecciona documentos desde tus asuntos o colecciones para agregarlos a la conversación.',
          extensions: ALLOWED_AMPARO_EXTS,
          existing: docIds,
          sizeLimit: environment.amparoSizeLimit,
        },
      })
      .subscribe((selection: any) => {
        if (selection) {
          this.assistantChatService.addDocs(selection);
        }
      });
  }
}
