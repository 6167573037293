import {
    Component,
    EventEmitter,
    inject,
    Output,
    ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
    CustomToolbarItemModel,
    DocumentEditorContainerComponent,
    ToolbarItem,
} from '@syncfusion/ej2-angular-documenteditor';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ALERT_DEFAULTS } from 'src/app/core/constants/alert-defaults.constants';
import { RESOURCES } from 'src/app/core/constants/resource-service.constants';
import { loadingState } from 'src/app/shared/operators/loading-state.operator';
import { UisrApiServiceV2 } from 'src/app/shared/services/uisr-api.service-v2';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-doc-editor',
    templateUrl: './doc-editor.component.html',
    styleUrls: ['./doc-editor.component.scss'],
})
export class DocEditorComponent {
    @ViewChild('document_editor')
    public editorContainer!: DocumentEditorContainerComponent;

    @Output()
    public onSaveDocxBlobDocument: any = new EventEmitter();

    @Output()
    public onSaveTxtBlobDocument: any = new EventEmitter();

    @Output()
    public onSaveSfdtBlobDocument: any = new EventEmitter();

    @Output()
    public onSavePdfBlobDocument: any = new EventEmitter();

    @Output()
    public onSaveFileDocument: any = new EventEmitter();

    @Output()
    public onCloseEditor: any = new EventEmitter();

    // Definir grupos de estados de carga
    loadingStates = {
        loadingDocument: new BehaviorSubject<boolean>(false), // Se esta cargando un documento
    };

    private readonly apiService = inject(UisrApiServiceV2);
    private editorReadySubject = new Subject<boolean>();
    editorReady$: Observable<boolean> = this.editorReadySubject.asObservable();
    public editorReady: boolean = false;
    public serviceLink: string = environment.apiDocumentEditorUrl;
    public docForm: FormGroup;
    public hasChanges: boolean = false;
    public initialHash!: number;
    public readOnly: boolean = false;

    toolbarItemOpen: CustomToolbarItemModel = {
        prefixIcon: 'e-de-ctnr-open',
        tooltipText: 'Abrir',
        text: 'Abrir',
        id: 'OpenFile',
    };

    // Boton para guardar el documento
    toolbarItemSave: CustomToolbarItemModel = {
        prefixIcon: 'e-de-ctnr-upload',
        tooltipText: 'Guardar',
        text: 'Guardar',
        id: 'Save',
    };

    // Botones de la barra de herramientas
    toolbarItems: (CustomToolbarItemModel | ToolbarItem)[] = [
        this.toolbarItemSave,
        'New',
        this.toolbarItemOpen,
        'Separator',
        'Undo',
        'Redo',
        'Separator',
        'Image',
        'Table',
        'Hyperlink',
        'Bookmark',
        'TableOfContents',
        'Separator',
        'Header',
        'Footer',
        'PageSetup',
        'PageNumber',
        'Break',
        'InsertFootnote',
        'InsertEndnote',
        'Separator',
        'Find',
        'Separator',
        'Comments',
        'TrackChanges',
        'Separator',
        'LocalClipboard',
        'RestrictEditing',
        'Separator',
        'FormFields',
        'UpdateFields',
    ];

    private readonly formBuilder = inject(FormBuilder);

    constructor() {
        // Inicializar formulario
        this.docForm = this.formBuilder.group({
            title: ['Nuevo Documento', [Validators.required]],
            documentId: [],
        });
    }

    /**
     * Al estar creado el editor
     */
    onEditorCreated(): void {
        this.editorReady = true;
        this.editorReadySubject.next(this.editorReady);
        this.initialHash = this.getContentHash();
        if (this.readOnly) {
            this.setReadOnly();
        }
    }

    /**
     * Establecer el editor en modo lectura
     */
    setReadOnly() {
        this.docForm.disable();
        this.editorContainer.documentEditor.isReadOnly = true;
        this.editorContainer.enableToolbar = false;
        this.editorContainer.showPropertiesPane = false;
    }

    /**
     * Interceptar solicitudes del editor
     */
    beforeXmlHttpRequestSend(event: any) {}

    /**
     * Cerrar el editor
     */
    closeEditor(): void {
        this.checkForChanges();

        if (this.hasChanges) {
            Swal.fire({
                ...ALERT_DEFAULTS,
                icon: 'warning',
                title: 'Tienes cambios sin guardar',
                html: 'Si abandonas esta página, perderás los cambios que aún no has guardado.<br>¿Estás seguro de que deseas continuar?',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Sí, abandonar sin guardar',
            }).then((res) => {
                if (res.isConfirmed) {
                    this.onCloseEditor.emit();
                }
            });
        } else {
            this.onCloseEditor.emit();
        }
    }

    /**
     * Click de los botones del toolbar
     */
    onToolbarClick(args: any): void {
        switch (args.item.id) {
            case 'Save':
                this.toolbarSaveFile();
                break;
            case 'OpenFile':
                this.toolbarOpenFile();
                break;
        }
    }

    /**
     * Abrir documento word programaticamente
     */
    toolbarOpenFile(): void {
        // Crear un elemento de entrada tipo file
        const inputElement = document.createElement('input');
        inputElement.type = 'file';

        // Establecer que el input acepte solo archivos .docx
        inputElement.accept =
            '.docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document';

        // Establecer un manejador de eventos para cuando se seleccione un archivo
        inputElement.onchange = (event: Event) => {
            const file = (event.target as HTMLInputElement).files?.[0];
            if (file) {
                this.loadDocument(file);
            }

            inputElement.remove();
        };

        // Agregar un manejador de eventos `oncancel` para detectar si el usuario cancela
        inputElement.onblur = () => {
            // Asegurarse de eliminar el input si se cancela la selección
            inputElement.remove();
        };

        // Simular un clic en el input para abrir el cuadro de selección de archivos
        inputElement.click();
    }

    /**
     * Cargar documento por url
     */
    loadDocumentUrl(url: string, initial: boolean = false): void {
        this.apiService
            .post(`${RESOURCES.documentEditorSfdtByUrl}`, {
                url: url,
            })
            .pipe(loadingState(this.loadingStates.loadingDocument))
            .subscribe((response: any) => {
                if (response.data.sfdt) {
                    this.editorContainer.documentEditor.open(
                        JSON.stringify(response.data)
                    );
                    if (initial) {
                        this.initialHash = this.getContentHash();
                    }
                }
            });
    }

    /**
     * Cargar documento
     */
    loadDocument(file: File, initial: boolean = false): void {
        let formData: FormData = new FormData();
        formData.append('files', file);

        this.apiService
            .post(`${RESOURCES.documentEditorSfdtByFile}`, formData)
            .pipe(loadingState(this.loadingStates.loadingDocument))
            .subscribe((response: any) => {
                if (response.data.sfdt) {
                    this.editorContainer.documentEditor.open(
                        JSON.stringify(response.data)
                    );
                    this.docForm.patchValue({
                        title: file.name.substring(
                            0,
                            file.name.lastIndexOf('.')
                        ),
                    });
                    if (initial) {
                        this.initialHash = this.getContentHash();
                    }
                }
            });
    }

    /**
     * Accion del boton de guardar
     */
    toolbarSaveFile() {
        this.docForm.updateValueAndValidity();
        if (this.docForm.valid) {
            this.saveFileAsDocx();
        }
    }

    /**
     * Guardar como MSWord
     */
    async saveFileAsDocx(): Promise<void> {
        let blob = await this.editorContainer.documentEditor.saveAsBlob('Docx');
        this.onSaveDocxBlobDocument.emit(blob);
        this.onSaveFileDocument.emit(
            new File([blob], `${this.docForm.value.title}.docx`, {
                type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            })
        );
    }

    /**
     * Guardar como Txt
     */
    async saveFileAsTxt(): Promise<void> {
        let blob = await this.editorContainer.documentEditor.saveAsBlob('Txt');
        this.onSaveTxtBlobDocument.emit(blob);
        this.onSaveFileDocument.emit(
            new File([blob], `${this.docForm.value.title}.txt`, {
                type: 'text/plain',
            })
        );
    }

    /**
     * Guardar como SyncFusionDocumentText
     */
    async saveFileAsSfdt(): Promise<void> {
        let blob = await this.editorContainer.documentEditor.saveAsBlob('Sfdt');
        this.onSaveSfdtBlobDocument.emit(blob);
        this.onSaveFileDocument.emit(
            new File([blob], `${this.docForm.value.title}.sfdt`, {
                type: 'application/json',
            })
        );
    }

    /**
     * Guardar como Pdf
     */
    /*async saveFileAsPdf(): void {
    let blob = await this.editorContainer.documentEditor.saveAsBlob('Pdf');
    this.onSavePdfBlobDocument.emit(blob);
    this.onSaveFileDocument.emit(new File([blob], `${this.docForm.value.title}.pdf`, {
      type: 'application/pdf'
    }));
  }*/

    /**
     * Al cambiar el contenido
     */
    onContentChange(event: any) {}

    /**
     * Al cambiar el documento por completo
     */
    onDocumentChange(event: any) {}

    /**
     * Comprueba si hay cambios en el documento
     */
    checkForChanges() {
        this.hasChanges =
            this.initialHash == this.getContentHash() ? false : true;
    }

    /**
     * Obtiene hash del contenido actual del editor
     */
    getContentHash() {
        let content = JSON.parse(
            this.editorContainer.documentEditor.serialize()
        );
        let contentStr = JSON.stringify({
            sec: content.sec,
            sty: content.sty,
        });
        return this.stringToHash(contentStr);
    }

    /**
     * Obtiene hash de un string
     */
    stringToHash(string: string) {
        let hash = 0;
        if (string.length == 0) return hash;
        for (let i = 0; i < string.length; i++) {
            let char = string.charCodeAt(i);
            hash = (hash << 5) - hash + char;
            hash = hash & hash;
        }
        return hash;
    }
}
