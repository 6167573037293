import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
    BehaviorSubject,
    debounceTime,
    distinctUntilChanged,
    forkJoin,
    Subject,
    switchMap,
} from 'rxjs';
import { RESOURCES } from 'src/app/core/constants/resource-service.constants';
import { APIResponse } from 'src/app/core/interfaces/api.interface';
import { UisrTools } from 'src/app/core/utils/uisr-tools';
import { STORAGE_OBJECT_TYPES } from 'src/app/features/law-firm/constants/storage-object-types.constants';
import {
    FilterStudioFile,
    StudioFiles,
} from 'src/app/features/law-firm/interfaces/studio-file.interface';
import { loadingState } from '../../operators/loading-state.operator';
import { UisrApiServiceV2 } from '../../services/uisr-api.service-v2';

@UntilDestroy()
@Component({
    selector: 'app-studio-folder-selector',
    templateUrl: './studio-folder-selector.component.html',
})
export class StudioFolderSelectorComponent {
    private readonly api = inject(UisrApiServiceV2);
    private readonly dialogData = inject(DIALOG_DATA, { optional: true });
    readonly dialogRef = inject(DialogRef, { optional: true });

    loading = new BehaviorSubject(false);
    loadingFolder = new BehaviorSubject(false);
    folders: any[] = [];
    selection = new Set<StudioFiles>();
    selectedFolder?: StudioFiles;
    searchControl: FormControl<null | string> = new FormControl(null);
    existingIds: number[] = [];
    filter: FilterStudioFile = {};
    filterSubject = new Subject<FilterStudioFile>();
    
    @Input() multiple = false;
    @Input() embedded = false;

    @Output() selectionChange = new EventEmitter<Set<StudioFiles>>();

    ngOnInit() {
        if (this.dialogData) {
            this.multiple = this.dialogData.multiple || false;
        }

        this.getFolderData();
        this.subscribeToSearch();
    }

    /** Al realizar la búsqueda, se obtienen las carpetas buscando en todo el estudio */
    subscribeToSearch() {
        this.searchControl.valueChanges
            .pipe(
                distinctUntilChanged(),
                debounceTime(300),
                untilDestroyed(this)
            )
            .subscribe({
                next: (val) => {
                    if (val) {
                        this.onSearch();
                    } else {
                        this.getFolderData();
                    }
                },
            });
    }

    onSearch() {
        this.applyFilter({
            search_value: this.searchControl.value || undefined,
        });
    }

    subscribeToFilter() {
        this.filterSubject
            .pipe(
                switchMap((filter: FilterStudioFile) => {
                    if (filter.search_value) {
                        let folderFilter: FilterStudioFile = {
                            ...filter,
                            parent_id: undefined,
                            type_id: STORAGE_OBJECT_TYPES.FOLDER,
                            page_number: undefined,
                            page_size: undefined,
                        };

                        folderFilter =
                            UisrTools.removeEmptyProperties(folderFilter);

                        return forkJoin([
                            this.api.get(RESOURCES.studioFiles, folderFilter),
                        ]).pipe(loadingState(this.loading));
                    } else {
                        return this.api
                            .get(RESOURCES.studioFolderDetails, filter)
                            .pipe(loadingState(this.loading));
                    }
                }),
                untilDestroyed(this)
            )
            .subscribe({
                next: (results) => {
                    if (Array.isArray(results)) {
                        const folderRes = results[0];

                        this.folders = folderRes.data;
                    } else {
                        const res: APIResponse<any> = results;
                        this.folders = res.data.folders;
                    }
                },
            });
    }

    applyFilter(filter: FilterStudioFile) {
        this.filter = {
            ...this.filter,
            ...filter,
            type_id: STORAGE_OBJECT_TYPES.FOLDER,
            exclude_thread_folders: true,
        };

        this.filter = UisrTools.removeEmptyProperties(this.filter);

        this.filterSubject.next(this.filter);
    }

    getFolderData() {
        this.selectedFolder = undefined;
        const filter: any = {
            type_id: STORAGE_OBJECT_TYPES.FOLDER,
            exclude_thread_folders: true,
        };

        this.api
            .get(RESOURCES.studioFolderDetails, filter)
            .pipe(loadingState(this.loading), untilDestroyed(this))
            .subscribe({
                next: (res: APIResponse<any>) => {
                    this.folders = res.data.folders;
                },
            });
    }

    /** Limpia el input de búsqueda y realiza la búsqueda de asuntos con los filtros por defecto */
    clearSearch() {
        this.searchControl.setValue(null);
        this.onSearch();
    }

    /** Al hacer click en una carpeta, se agrega o elimina del arreglo de elementos seleccionados */
    onFolderClick(folder: StudioFiles) {
        if (this.selection.has(folder)) {
            this.selection.delete(folder);
        } else if (this.multiple) {
            this.selection.add(folder);
        } else {
            this.selection.clear();
            this.selection.add(folder);
        }

        this.selectionChange.emit(this.selection);
    }

    /** Al solicitar abrir una carpeta, se busca su data y se abre */
    openFolder(folder: StudioFiles) {
        if (folder.open) {
            folder.open = false;
            return;
        }
        this.selectedFolder = folder;

        this.api
            .get(RESOURCES.studioFiles, {
                parent_id: folder.studio_file_id,
                type_id: STORAGE_OBJECT_TYPES.FOLDER,
                exclude_thread_folders: true,
            })
            .pipe(loadingState(this.loadingFolder), untilDestroyed(this))
            .subscribe({
                next: (res: APIResponse<any>) => {
                    console.log(res.data);
                    folder.folders = res.data;
                    folder.open = true;
                },
            });
    }

    /** Se ejecuta cuando el usuario confirma la selección, emite la selección de carpetas actual*/
    onSubmit() {
        // Listado de todas las carpetas seleccionadas
        const folders: any[] = Array.from(this.selection);

        this.dialogRef?.close(folders);
    }
}
