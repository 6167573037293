import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import {
  Component,
  computed,
  effect,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { isEqual } from 'lodash';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import {
  BehaviorSubject,
  distinctUntilChanged,
  Subject,
  switchMap,
} from 'rxjs';
import { ALERT_DEFAULTS } from 'src/app/core/constants/alert-defaults.constants';
import { RESOURCES } from 'src/app/core/constants/resource-service.constants';
import { APIResponse } from 'src/app/core/interfaces/api.interface';
import { UisrTools } from 'src/app/core/utils/uisr-tools';
import { PAGINATION_CONFIG } from 'src/app/features/law-firm/constants/pagination.constants';
import { ActivityFile } from 'src/app/features/law-firm/interfaces/activity.interfaces';
import { DossierFilter } from 'src/app/features/law-firm/interfaces/dossier-filter.interface';
import { StorageObjectFilter } from 'src/app/features/law-firm/interfaces/storage-object-filter.interface';
import { StorageObject } from 'src/app/features/law-firm/interfaces/storage-object.interface';
import { DossierIconStatusPipe } from 'src/app/features/law-firm/pipes/dossier-icon-status.pipe';
import { DossierStatusIconPipe } from 'src/app/features/law-firm/pipes/dossier-status-icon.pipe';
import { COLLECTION_TYPES } from 'src/app/features/search/constants/collections.constants';
import { SOURCES_WITH_IDS } from 'src/app/features/search/constants/sources-with-ids.constants';
import { CollectionLite } from 'src/app/features/search/interfaces/collections.interfaces';
import Swal from 'sweetalert2';
import { DossierDTO } from '../../../features/law-firm/interfaces/dossier.interfaces';
import { MenuModule } from '../../modules/menu.module';
import { AppPaginationModule } from '../../modules/pagination.module';
import { loadingState } from '../../operators/loading-state.operator';
import { CollectionsService } from '../../services/collections.service';
import { UisrApiServiceV2 } from '../../services/uisr-api.service-v2';
import { SimpleSearchObjectDataComponent } from './simple-search-object-data/simple-search-object-data.component';
import { SimpleStorageObjectDataComponent } from './simple-storage-object-data/simple-storage-object-data.component';

@UntilDestroy()
@Component({
  selector: 'app-simple-doc-picker',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    AppPaginationModule,
    NgxTippyModule,
    DossierIconStatusPipe,
    DossierStatusIconPipe,
    SimpleStorageObjectDataComponent,
    InfiniteScrollModule,
    ReactiveFormsModule,
    SimpleSearchObjectDataComponent,
    MenuModule,
  ],
  templateUrl: './simple-doc-picker.component.html',
})
export class SimpleDocPickerComponent implements OnInit {
  sizeLimit?: number;
  data? = inject(DIALOG_DATA, { optional: true });
  dialogRef? = inject(DialogRef, { optional: true });
  loading = new BehaviorSubject(false);
  scrolling = new BehaviorSubject(false);
  loadingFolder = new BehaviorSubject(false);
  loadingCollectionsData = new BehaviorSubject(false);
  selectedFiles: Set<ActivityFile> = new Set();
  selectedSearchObjects: Set<any> = new Set();
  existingIds: any[] = [];
  existingSearchObjectIds: any[] = [];
  tabs = [
    { label: 'Asuntos', action: 'dossiers' },
    { label: 'Colecciones', action: 'collections' },
  ];
  selectedTab = this.tabs[0].action;
  dossiers: DossierDTO[] = [];
  dossierFilterSubject = new Subject<DossierFilter>();
  dossierFilter: DossierFilter = {};
  dossierPagination = structuredClone(PAGINATION_CONFIG);
  selectedFolder?: StorageObject;
  selectedDossier?: DossierDTO;
  selectedCollection?: CollectionLite;
  filteredSearchObjects: any[] = [];
  searchControl: FormControl<null | string> = new FormControl(null);
  filteredCollections: CollectionLite[] = [];
  selectedDossierFileType = 'documents';

  searchObjects: any[] = []; // Listado de documentos de una colección
  storageObjects: StorageObject[] = []; // Listado de documentos o carpetas de una asunto

  private readonly _dossierUrl = '/law-firm/files/file-overview';
  private readonly _apiService = inject(UisrApiServiceV2);
  private readonly _router = inject(Router);
  private readonly _collectionsService = inject(CollectionsService);
  private readonly _translateService = inject(TranslateService);

  collections = computed(() => {
    return this._collectionsService
      .userCollections()
      .filter((c) => c.fk_id_type_collection != COLLECTION_TYPES.DOSSIERS);
  });

  @Input() prompt?: string;
  @Input() extensions?: string[] = [];

  @Output() selection = new EventEmitter<any>();

  constructor() {
    this.dossierPagination.id = 'dossier-pagination';
    if (this.data) {
      this.prompt = this.data.prompt;
      this.extensions = this.data.extensions || [];
      this.existingIds = this.data.existing || [];
      this.sizeLimit = this.data.sizeLimit || undefined;
    }

    effect(() => (this.filteredCollections = this.collections()));
  }

  ngOnInit() {
    this.subscribeToDossierFilter();
    if (this.selectedTab == 'dossiers') {
      this.applyDossierFilter({ pageNumber: 1, pageSize: 10 });
    }
  }

  /** Permite hacer modificaciones al filtro de asuntos antes de realizar una búsqueda, elimina los campos vacíos */
  applyDossierFilter(newFilter: DossierFilter) {
    this.dossierFilter = { ...this.dossierFilter, ...newFilter };

    this.dossierFilter = UisrTools.removeEmptyProperties(this.dossierFilter);

    this.dossierFilterSubject.next(this.dossierFilter);
  }

  /** Se ejecuta cuando el usuario confirma la selección, emite los ids de los documentos seleccionados */
  onSubmit() {
    // Los documentos seleccionados desde un dossier se mandan completos junto al tipo dossierFile
    const files: any[] = Array.from(this.selectedFiles).map((f) => ({
      ...f,
      type: 'dossierFile',
    }));

    // Los documentos seleccionados desde una colección se mandan con el index de la fuente, su id de openSearch y el tipo searchObject
    this.selectedSearchObjects.forEach((searchObject) => {
      files.push({
        id: searchObject._id,
        index: SOURCES_WITH_IDS.find((s) => s.index == searchObject._index)?.id,
        openSearchId: searchObject._id,
        type: 'searchObject',
        name: searchObject.heading,
      });
    });

    this.dialogRef?.close(files);
    this.selection.emit(files);
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  /** Permite cambiar la pestaña seleccionada en el componente solo si la nueva pestaña es diferente a la actual */
  changeTab(action: string) {
    if (this.selectedTab != action) {
      this.selectedTab = action;

      if (this.selectedTab == 'dossiers') {
        this.applyDossierFilter({ pageNumber: 1, pageSize: 10 });
      }
    } else {
      this.selectedDossier = undefined;
    }
  }

  /** Permite cambiar el tipo de documentos del asunto entre documentos cargados por el usuario o legislación (search_objects agregados a la colección del selectedDossier) */
  changeDossierFileType(action: string) {
    if (this.selectedDossierFileType != action) {
      this.selectedCollection = undefined;
      this.selectedDossierFileType = action;

      if (
        this.selectedDossierFileType == 'legislation' &&
        this.selectedDossier
      ) {
        const dossierCollection = this._collectionsService
          .userCollections()
          .find((c) => c.fk_id_dossier == this.selectedDossier?.idDossier);
        if (dossierCollection) {
          this.selectCollection(dossierCollection);
        }
      }
    }
  }

  /** Se suscribe al observable del filtro de asuntos para realizar una búsqueda en cuanto hayan cambios */
  subscribeToDossierFilter() {
    this.dossierFilterSubject
      .pipe(
        distinctUntilChanged(isEqual),
        switchMap((filter) => {
          this.dossierPagination.currentPage = filter.pageNumber || 1;
          this.dossierPagination.itemsPerPage = filter.pageSize || 10;

          return this._apiService
            .post(RESOURCES.dossierList, filter)
            .pipe(loadingState(this.loading));
        }),
        untilDestroyed(this)
      )
      .subscribe({
        next: (res: APIResponse<DossierDTO[]>) => {
          this.dossiers = res.data;
          this.dossierPagination.totalItems = res.total || 0;

          this._setDefaultDossier();
        },
      });
  }

  /** Verifica si el componente se abrió en la ruta de detalle de un asunto y si es así, selecciona el asunto abierto como asunto por defecto */
  private _setDefaultDossier() {
    const url = this._router.url;
    if (url.includes(this._dossierUrl)) {
      let id = url.split('?')[0].split('/').pop();

      let selected = this.dossiers.find((x) => x.idDossier == Number(id));
      if (!selected) return;

      this.selectDossier(selected);
    }
  }

  /** Limpia el input de búsqueda y realiza la búsqueda de asuntos con los filtros por defecto */
  clearSearch() {
    this.searchControl.setValue(null);
    this.onSearch();
  }

  /** Al realizar la búsqueda, si se ha seleccionado un asunto, buscar en storage objects, de lo contrario, filtrar los asuntos */
  onSearch() {
    if (this.selectedTab == 'dossiers') {
      if (this.selectedDossier) {
        if (this.searchControl.value) {
          this.onStorageObjectSearch();
        } else {
          this.getFolderData();
        }
      } else {
        this.applyDossierFilter({
          pageNumber: 1,
          searchValue: this.searchControl.value || undefined,
        });
      }
    } else if (this.selectedTab == 'collections') {
      if (this.selectedCollection) {
        this.filteredSearchObjects = this.searchObjects.filter(
          (c) =>
            !this.searchControl.value ||
            c.heading?.toLowerCase().includes(this.searchControl.value)
        );
      } else {
        this.filteredCollections = this.collections().filter(
          (c) =>
            !this.searchControl.value ||
            c.name_collection?.toLowerCase().includes(this.searchControl.value)
        );
      }
    }
  }

  /** Realiza la búsqueda de storage object con el valor del input de búsqueda de carpetas y documentos */
  onStorageObjectSearch() {
    const filter: StorageObjectFilter = {};
    filter.dossierId = Number(this.selectedDossier?.idDossier);
    filter.searchValue = this.searchControl.value || undefined;
    if (!filter.dossierId) return;

    filter.fileExts = this.extensions;

    this._apiService
      .get(RESOURCES.storageObjects, filter)
      .pipe(loadingState(this.loading), untilDestroyed(this))
      .subscribe({
        next: (res: APIResponse<any>) => {
          this.storageObjects = res.data;

          this.selectedFolder = undefined;
        },
      });
  }

  /** Al hacer click en un documento o carpeta, se determina si se debe consultar la información extra o si se agrega al arreglo de documentos seleccionados */
  onStorageObjectClick(storageObject: StorageObject) {
    if (storageObject.file) {
      if (this.selectedFiles.has(storageObject.file)) {
        this.selectedFiles.delete(storageObject.file);
      } else {
        this.selectedFiles.add(storageObject.file);
      }
    } else {
      if (storageObject.storageObjects && storageObject.open) {
        storageObject.open = false;
      } else if (!storageObject.storageObjects) {
        storageObject.open = true;
        this.getFolderData(storageObject);
      } else {
        storageObject.open = true;
      }
    }
  }

  /** Al hacer click en un searchObject, se agrega o elimina del arreglo de elementos seleccionados */
  onSearchObjectClick(searchObject: any) {
    if (this.selectedSearchObjects.has(searchObject)) {
      this.selectedSearchObjects.delete(searchObject);
    } else {
      this.selectedSearchObjects.add(searchObject);
    }
  }

  /** Permite obtener los datos de una carpeta, obtiene sus documentos y sub-carpetas */
  getFolderData(folder?: StorageObject) {
    const filter: StorageObjectFilter = {};
    if (folder) {
      this.selectedFolder = folder;
      filter.dossierId = Number(this.selectedFolder.dossierId);
      filter.parentId = Number(this.selectedFolder.id);
    } else {
      filter.dossierId = Number(this.selectedDossier?.idDossier);
    }

    filter.fileExts = this.extensions;

    if (!filter.dossierId) return;

    this._apiService
      .get(RESOURCES.storageObjectDetails, filter)
      .pipe(loadingState(this.loadingFolder), untilDestroyed(this))
      .subscribe({
        next: (res: APIResponse<any>) => {
          if (!folder) {
            this.storageObjects = [...res.data.folders, ...res.data.files];
          } else {
            folder.storageObjects = [...res.data.folders, ...res.data.files];
            folder.totalFiles = res.data.totalFiles || 0;
          }

          this.selectedFolder = undefined;
        },
      });
  }

  /** Permite seleccionar un asunto y consultar sus documentos y carpetas. Solo se ejecuta si el asunto seleccionado es diferente al actual */
  selectDossier(dossier: DossierDTO) {
    if (this.selectedDossier?.idDossier != dossier.idDossier) {
      this.selectedDossier = dossier;
      this.getFolderData();
    }
  }

  /** Permite seleccionar una colección y consultar sus search_objects. Solo se ejecuta si la colección seleccionada es diferente a la actual */
  selectCollection(collection: CollectionLite) {
    if (
      this.selectedCollection?.id_collection_user !=
      collection.id_collection_user
    ) {
      this.selectedCollection = collection;
      this.getCollectionData();
    }
  }

  /** Obtiene los searchObjects asociados a la colección seleccionada */
  getCollectionData() {
    if (!this.selectedCollection) return;

    let data: any = {
      num: 10000,
      page: 1,
      id_collection_user: this.selectedCollection.id_collection_user,
    };

    this._apiService
      .get(RESOURCES.getCollectionDetails, data)
      .pipe(loadingState(this.loadingCollectionsData), untilDestroyed(this))
      .subscribe({
        next: (res: any) => {
          if (res.success) {
            this.searchObjects = res.search_objects.data;
            this.filteredSearchObjects = this.searchObjects;
          } else {
            let message = this._translateService.instant(
              'GENERIC_ERROR_MESSAGE'
            );
            if (res.code) {
              let messageAux = this._translateService.instant(res.code);
              message = messageAux != res.code ? messageAux : message;
            }
            Swal.fire({
              ...ALERT_DEFAULTS,
              text: message,
            });
          }
        },
      });
  }

  /** Buscar la siguiente página de asuntos al hacer scroll */
  nextPage() {
    if (
      this.dossierPagination.totalItems &&
      this.dossierPagination.totalItems > this.dossiers.length
    ) {
      this.dossierFilter.pageNumber = this.dossierPagination.currentPage + 1;
      this._apiService
        .post(RESOURCES.dossierList, this.dossierFilter)
        .pipe(loadingState(this.scrolling), untilDestroyed(this))
        .subscribe({
          next: (res: APIResponse<DossierDTO[]>) => {
            this.dossiers.push(...res.data);
            this.dossierPagination.currentPage =
              this.dossierFilter.pageNumber || 1;
            this.dossierPagination.itemsPerPage =
              this.dossierFilter.pageSize || 10;
            this.dossierPagination.totalItems = res.total || 0;
          },
        });
    }
  }

  /** Obtiene el texto que se debe mostrar en el searchInput según la vista en la que está el usuario */
  getInputPlaceholder() {
    if (this.selectedTab == 'dossiers') {
      return this.selectedDossier
        ? 'Busca por nombre del documento o carpeta'
        : 'Busca por titulo, número u órgano del asunto';
    } else {
      return this.selectedCollection
        ? 'Busca por nombre del documento'
        : 'Busca por nombre de la colección';
    }
  }

  /** Volver al listado principal ya sea de asuntos o de colecciones según selectedTab y reiniciar los listados de documentos */
  returnToList() {
    this.selectedDossier = undefined;
    this.selectedCollection = undefined;
    this.storageObjects = [];
    this.searchObjects = [];
  }
}
