<div
  class="px-5 py-3 border-b bg-pearl-100 border-slate-200 sticky top-0 flex justify-between items-center min-w-0 gap-4">
  <span class="font-semibold text-primary-100 truncate">Documento Adjunto</span>
  <div class="flex">
    <!-- Remover documento de la conversación -->
    <button *ngIf="canRemove" ngxTippy="Eliminar de la conversación"
      [tippyProps]="{ hideOnClick: true, trigger: 'mouseenter' }"
      class="grid place-content-center size-8 text-slate-400 hover:text-rose-500 hover:bg-slate-300/60 hover:shadow-sm rounded"
      (click)="chatService.removeDoc(doc)">
      <span class="sr-only">Eliminar de a conversación</span>
      <i class="fa-solid text-[1rem] fa-trash-xmark"></i>
    </button>
    <!-- Abrir previsualizador de PDF dentro de la app -->
    <button *ngIf="doc.extension == 'pdf'" ngxTippy="Ver Documento"
      [tippyProps]="{ hideOnClick: true, trigger: 'mouseenter' }"
      class="grid place-content-center size-8 text-slate-400 hover:text-slate-500 hover:bg-slate-300/60 hover:shadow-sm rounded"
      (click)="preview()">
      <span class="sr-only">Previsualizar</span>
      <i class="fa-solid text-[1rem] fa-magnifying-glass"></i>
    </button>
    <!-- Descargar documento -->
    <button ngxTippy="Descargar" [tippyProps]="{ hideOnClick: true, trigger: 'mouseenter' }" *ngIf="doc.idActivityFile"
      class="grid place-content-center size-8 text-slate-400 hover:text-slate-500 hover:bg-slate-300/60 hover:shadow-sm rounded"
      (click)="download()" [disabled]="downloading | async">
      <span class="sr-only">Descargar</span>
      <i class="fa-solid text-[1rem]"
        [ngClass]="(downloading | async) ? 'fa-spinner-third animate-spin' : 'fa-arrow-down-to-bracket'"></i>
    </button>
    <!-- Cerrar el modal -->
    <button ngxTippy="Cerrar" [tippyProps]="{ hideOnClick: true, trigger: 'mouseenter' }"
      class="grid place-content-center size-8 text-slate-400 hover:text-rose-500 hover:bg-slate-300/60 hover:shadow-sm rounded"
      (click)="dialogRef.close()">
      <span class="sr-only" translate="CLOSE_STRING"></span>
      <i class="fa-solid text-[1rem] fa-xmark"></i>
    </button>
  </div>

</div>

<div class="overflow-auto p-5 max-h-[calc(85svh-11.4rem)] flex flex-col w-[90svw] max-w-2xl">
  <div *ngIf="loading | async" class="animate-pulse flex flex-col gap-4 pointer-events-none">
    <div class="bg-slate-200 rounded h-6 w-2/3"></div>
    <div class="bg-slate-200 rounded h-8"></div>
    <div class="bg-slate-200 rounded h-6 w-2/3"></div>
    <div class="bg-slate-200 rounded h-28"></div>
    <div class="bg-slate-200 rounded h-6 w-2/3"></div>
    <div class="flex flex-wrap gap-2">
      <div *ngFor="let _ of [].constructor(10)" class="bg-slate-200 rounded-full h-6 w-16"></div>
    </div>
    <div class="bg-slate-200 rounded h-6 w-2/3"></div>
    <div class="bg-slate-200 rounded h-8"></div>
  </div>
  <div *ngIf="!(loading | async)" class="flex flex-col gap-2">
    <h2 class="font-medium text-lg">
      <i class="fa-regular fa-file"></i>
      Detalles
    </h2>
    <ng-container *ngIf="doc.idActivityFile">
      <p class="text-slate-600" *ngIf="doc.ai_concept">Concepto: <span class="font-medium">{{ doc.ai_concept }}</span>
      </p>
      <p class="text-slate-600">Nombre: <span class="font-medium">{{ doc.name }}</span></p>
      <ng-container *ngIf="doc.activity?.dossier">
        <p class="text-slate-600">Asunto: <span class="font-medium">({{ doc.activity.dossier.numberDossier }}) {{
            doc.activity.dossier.name }}</span></p>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="doc.id_open_search">
      <p class="text-slate-600" *ngIf="doc.attributes?.ai_concept">Concepto: <span class="font-medium">{{
          doc.attributes?.ai_concept }}</span></p>
      <p class="text-slate-600">Nombre: <span class="font-medium">{{ doc.heading | titlecase }}</span></p>
      <ng-container *ngIf="doc.activity?.dossier">
        <p class="text-slate-600">Asunto: <span class="font-medium">({{ doc.activity.dossier.numberDossier }}) {{
            doc.activity.dossier.name }}</span></p>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="doc.studio_file_id">
      <p class="text-slate-600" *ngIf="doc.ai_concept">Concepto: <span class="font-medium">{{ doc.ai_concept }}</span>
      </p>
      <p class="text-slate-600">Nombre: <span class="font-medium">{{ doc.name }}</span></p>
    </ng-container>
    <h2 class="font-medium text-lg mt-5"
      *ngIf="(doc.ai_keywords && doc.ai_keywords[0]) || (doc.attributes?.ai_keywords && doc.attributes?.ai_keywords[0])">
      <i class="fa-regular fa-tags"></i>
      Palabras Clave
    </h2>
    <div class="flex flex-wrap gap-2" *ngIf="doc.ai_keywords && doc.ai_keywords[0]">
      <span *ngFor="let word of doc.ai_keywords"
        class="inline-flex px-2.5 py-1 text-slate-600 bg-slate-100 border border-slate-200 rounded-full text-sm shadow-sm">{{word}}</span>
    </div>
    <div class="flex flex-wrap gap-2" *ngIf="doc.attributes?.ai_keywords && doc.attributes?.ai_keywords[0]">
      <span *ngFor="let word of doc.attributes.ai_keywords"
        class="inline-flex px-2.5 py-1 text-slate-600 bg-slate-100 border border-slate-200 rounded-full text-sm shadow-sm">{{word}}</span>
    </div>
    <h2 class="font-medium text-lg mt-5" *ngIf="doc.ai_summary || doc.attributes?.ai_summary">
      <i class="fa-regular fa-align-left"></i>
      Resumen
    </h2>
    <p class="text-slate-600" *ngIf="doc.ai_summary || doc.attributes?.ai_summary">{{ doc.ai_summary ||
      doc.attributes?.ai_summary }}</p>

    <div *ngIf="running | async"
      class="px-4 py-2 rounded-sm text-sm bg-indigo-100 border border-indigo-200 text-indigo-500">
      <div class="flex w-full justify-start items-center gap-2">
        <div class="flex gap-2 items-center animate-spin">
          <i class="fa-solid fa-spinner-third"></i>
        </div>
        <div class="loading">Amparo IA está procesando este documento, en breve tendrás el resumen.</div>
      </div>
    </div>
  </div>

</div>
<div class="py-5 px-4 border-t bg-pearl-100 border-slate-200 sticky bottom-0 flex flex-wrap justify-end gap-2">
  <button class="uisr-secondary-button bg-slate-100" (click)="dialogRef.close()" translate="BACK_STRING">
  </button>
</div>